<!--scr/components/StartPage.vue -->
<template>
  <main class="pt-4 px-4 pb-4">
    <div
      class="d-flex justify-content-between align-items-center mb-4 border-bottom"
    >
      <div>
        <h1 class="h2">Startseite</h1>
        <p>Stöbere in unseren Top-Kategorien!</p>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 g-4">
      <div v-for="category in categories" :key="category.uuid" class="col">
        <div class="card h-100">
          <img
            :src="category.imageUrl"
            class="card-img-top category-image"
            alt="category image"
          />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">{{ category.title }}</h5>
            <p class="card-text">{{ category.description }}</p>
            <div
              class="mt-auto d-flex justify-content-between align-items-center"
            >
              <button
                class="btn btn-primary flex-grow-1"
                @click="navigateToCategory(category.uuid)"
              >
                Module entdecken
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { ref } from "vue";
import axiosInstance from "@/axiosInstance";
import logger from "@/utils/logging";
import { useRouter } from "vue-router";
import { ICategory } from "@backend/models/Category";
import { useUserStore } from "@/store/user";

const categories = ref<ICategory[]>([]);
const router = useRouter();

const fetchCategories = async () => {
  try {
    const response = await axiosInstance.get("/api/categories?startPage=true");
    categories.value = response.data;
  } catch (error) {
    logger.userErr("Fehler beim Laden der Kategorien", error);
  }
};

const navigateToCategory = (categoryId: string) => {
  router.push("/categories/" + categoryId);
};

const user = useUserStore();
document.title = `${user.pageTitle} - Startseite`;
fetchCategories();
</script>

<style scoped>
.category-image {
  height: 200px;
  object-fit: contain;
}
</style>
