<!-- frontend/src/components/Modals/FeedbackModal.vue -->
<template>
  <ModalComponent
    name="Feedback"
    title="Feedback senden"
    action-button-text="Senden"
    action-button-color="btn-primary"
    dismiss-button-text="Abbrechen"
    dismiss-button-color="btn-secondary"
    :action-callback="sendFeedback"
  >
    <form>
      <div class="mb-3">
        <label for="feedback-text" class="form-label">
          Du hast einen Fehler entdeckt, eine Idee für eine neue Funktion oder
          sonstiges Feedback?
        </label>
        <textarea
          class="form-control"
          id="feedback-text"
          rows="3"
          v-model="feedbackText"
          placeholder="Schreib uns hier! Versuche dein Thema so genau wie möglich zu beschreiben 😊"
        ></textarea>
      </div>
    </form>
  </ModalComponent>
</template>

<script setup lang="ts">
import { ref } from "vue";
import axiosInstance from "@/axiosInstance";
import logger from "@/utils/logging";
import ModalComponent from "@/components/ModalComponent.vue";

const feedbackText = ref<string>("");

const sendFeedback = async () => {
  try {
    await axiosInstance.post("/api/submission/feedback", {
      feedback: feedbackText.value,
      url: window.location.href,
    });

    feedbackText.value = "";
    logger.userSuccess("Feedback erfolgreich versendet, vielen Dank!");
  } catch (error) {
    logger.userErr("Fehler beim Senden des Feedbacks", error);
  }
};
</script>
