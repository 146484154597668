// frontend/src/axiosInstance.ts
import axios, { AxiosError } from "axios";
import { useAuthStore } from "@/store/auth";
import EventBus from "@/eventBus";
import { checkForMajorVersionUpdate } from "@/utils";
import logger from "@/utils/logging";
import { version } from "../../package.json";
import * as Sentry from "@sentry/vue";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL as string,
  timeout: 300000, // Set the timeout to 5 minutes (300000 milliseconds)
});

/**
 * Add a request interceptor to add the token to the request header.
 * Avoid non-silent (redirect) token refreshes when the request is not a GET request.
 */
axiosInstance.interceptors.request.use(
  async (config) => {
    let silentRefresh = false;
    if (config.method !== "get" && config.method !== "GET") {
      silentRefresh = true;
    }
    const token = await useAuthStore().getToken(silentRefresh);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["X-Version"] = version;
    config.headers["X-Replay-ID"] =
      (Sentry.getCurrentHub()
        .getIntegration(Sentry.Replay)
        ?.getReplayId() as string) || "No replay ID found";
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// Resolving function for the user choice modal
export let modalPromiseResolver: (value: boolean) => void;
let modalOpen = false;

/**
 * Add a response interceptor to handle 401 errors.
 * If the error is a 401 Unauthorized error, show the user a modal to choose whether to refresh the token or not.
 */
axiosInstance.interceptors.response.use(
  (response) => {
    checkForMajorVersionUpdate(response.headers["x-version"]);
    return response;
  },
  async (error: any) => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      error.response?.data?.message === "Unauthorized (invalid token)" &&
      !modalOpen
    ) {
      logger.debugInfo("Session expired, showing modal");
      const isRefreshChosen = new Promise<boolean>((resolve) => {
        modalPromiseResolver = resolve;
      });
      EventBus.emit("showSessionExpiredModal");
      modalOpen = true;
      if (await isRefreshChosen) {
        modalOpen = false;
        logger.debugInfo("Refreshing token");
        const token = await useAuthStore().getToken(false);
        // The following code may not execute if the token refresh triggers a redirect (non-silent refresh)
        if (originalRequest) {
          originalRequest.headers["Authorization"] = "Bearer " + token;
          return axiosInstance(originalRequest);
        } else {
          logger.debugErr("Original request not found", error);
          return Promise.reject(Error("Original request not found"));
        }
      } else {
        logger.debugInfo("Doing nothing");
        return Promise.reject(Error("Session expired"));
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
