<!-- frontend/src/components/MainContent.vue -->
<template>
  <main :class="auth.account ? 'col-md-10 ms-sm-auto col-lg-10 px-md-0 page-content' : 'col-md-12 ms-sm-auto col-lg-12 px-md-0 page-content'"
        v-bind:style="{
                 background: auth.account ? '' : 'url(https://emvia.de/wp-content/uploads/2023/09/headerstart.webp) no-repeat center',
                 backgroundSize: auth.account ? '' : 'cover',
                 maxHeight: auth.account ? '' : '80%',
                 height: auth.account ? '' : '100%'
               }">
      <div
          id="content"
          class="overflow-auto flex-grow-1 content-container main-container h-100"
      >
        <div class="content-inner h-100">
          <router-view v-if="isInitialized" :key="$route.fullPath"/>
          <LoadingScreen
              sub-title="Anwendung wird geladen..."
              :title="user.pageTitle"
              v-else
          >
            <p class="mt-4">
              Wenn diese Meldung länger als 10 Sekunden angezeigt wird,
              <a @click="clearAndReload" href="#">klicke hier</a>, um die Seite
              neu zu laden.
            </p>
          </LoadingScreen>
        </div>
      </div>
    </main>
</template>

<script setup lang="ts">
import LoadingScreen from "@/components/LoadingScreen.vue";
import {useAuthStore} from "@/store/auth";
import {useUserStore} from "@/store/user";
import {computed} from "vue";
import {useRoute} from "vue-router";

const user = useUserStore();
const auth = useAuthStore();
const route = useRoute();
const isInitialized = computed(
    () =>
        auth.isInitialized ||
        route.name === "auth-callback" ||
        route.name === "signin",
);

const clearAndReload = () => {
  auth.logout();
  location.reload();
};
</script>

<style>
.page-content {
  padding-top: 58px;
}

.main-container {
  max-height: calc(100vh - 58px);
  overflow-y: auto;
}

.loading-screen {
  height: calc(100vh - 58px);
}
</style>
