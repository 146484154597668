<!-- frontend/src/components/SidebarMenu.vue -->
<template>
  <div>
    <nav
        v-if="auth.account"
        id="sidebarMenu"
        class="col-md-3 col-lg-2 d-none d-md-block sidebar fixed-top sidebar-menu"
    >
      <div class="border-end">
        <div class="position-sticky pt-4">
          <MainMenu/>
        </div>
      </div>
    </nav>
    <div
        class="offcanvas offcanvas-start d-md-none"
        tabindex="-1"
        id="offcanvasMenu"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Menü</h5>
        <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Schließen"
        ></button>
      </div>
      <div class="offcanvas-body">
        <MainMenu/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MainMenu from "@/components/MainMenu.vue";
import {Offcanvas} from "bootstrap";
import {useAuthStore} from "@/store/auth";
import EventBus from "@/eventBus";
import {onBeforeUnmount, onMounted} from "vue";

const auth = useAuthStore();

onMounted(() => {
  const myOffcanvas = document.getElementById(
      "offcanvasMenu",
  ) as HTMLDivElement;
  const bsOffcanvas = new Offcanvas(myOffcanvas);

  EventBus.on("showMenuOffcanvas", () => {
    bsOffcanvas.show();
  });

  EventBus.on("hideMenuOffcanvas", () => {
    bsOffcanvas.hide();
  });
});

onBeforeUnmount(() => {
  EventBus.off("showMenuOffcanvas");
  EventBus.off("hideMenuOffcanvas");
});
</script>

<style>
.sidebar-menu {
  padding-top: 58px;
}
</style>
