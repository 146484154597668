<!-- frontend/src/components/MainMenu.vue -->
<template v-if="auth.isValid() || auth.account">
  <ul class="nav flex-column menu-height flex-nowrap overflow-auto">
    <li class="nav-item" v-if="auth.account">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon icon="home" :class="iconStyle" class="fs-4 m-2" /><span
          class="ms-2 text-black"
          >Startseite</span
        >
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account && user.perms.createModules">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/my-templates"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon icon="user" :class="iconStyle" class="fs-4 m-2" /><span
          class="ms-2 text-black"
          >Meine Module</span
        >
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/my-favorites"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon icon="star" :class="iconStyle" class="fs-4 m-2" /><span
          class="ms-2 text-black"
          >Meine Favoriten</span
        >
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/categories"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon icon="list" :class="iconStyle" class="fs-4 m-2" /><span
          class="ms-2 text-black"
          >Alle Module</span
        >
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account">
      <router-link
          class="nav-link fs-6 d-flex align-items-center"
          active-class="active-link"
          to="/chatbot"
          @click="toggleOffcanvas"
      >
        <FontAwesomeIcon icon="robot" :class="iconStyle" class="fs-4 m-2" /><span
          class="ms-2 text-black"
      >Chatbot</span
      >
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account">
      <router-link
          class="nav-link fs-6 d-flex align-items-center"
          active-class="active-link"
          to="/dall-e"
          @click="toggleOffcanvas"
      >
        <FontAwesomeIcon icon="image" :class="iconStyle" class="fs-4 m-2" /><span
          class="ms-2 text-black"
      >Bild Generator (DALL-E)</span
      >
      </router-link>
    </li>
    <hr
      v-if="auth.account && user.profile.role.name === 'admin'"
      class="my-3"
    />
    <li class="nav-item" v-if="auth.account && user.perms.manageModules">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/manage-templates"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon icon="cogs" :class="iconStyle" class="fs-4 m-2" /><span
          class="ms-2 text-black"
          >Module verwalten</span
        >
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account && user.perms.manageCategories">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/manage-categories"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon
          icon="folder-open"
          :class="iconStyle"
          class="fs-4 m-2"
        /><span class="ms-2 text-black">Kategorien verwalten</span>
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account && user.perms.manageUsers">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/manage-users"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon
          icon="users-cog"
          :class="iconStyle"
          class="fs-4 m-2"
        /><span class="ms-2 text-black">Benutzer verwalten</span>
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account && user.perms.manageUsers">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/manage-roles"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon
          icon="user-shield"
          :class="iconStyle"
          class="fs-4 m-2"
        /><span class="ms-2 text-black">Rollen verwalten</span>
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account && user.perms.manageModules">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/manage-models"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon
          icon="microchip"
          :class="iconStyle"
          class="fs-4 m-2"
        /><span class="ms-2 text-black">KI-Modelle verwalten</span>
      </router-link>
    </li>
    <li class="nav-item" v-if="auth.account && user.perms.viewStats">
      <router-link
        class="nav-link fs-6 d-flex align-items-center"
        active-class="active-link"
        to="/statistics"
        @click="toggleOffcanvas"
      >
        <FontAwesomeIcon
          icon="chart-bar"
          :class="iconStyle"
          class="fs-4 m-2"
        /><span class="ms-2 text-black">Statistiken</span>
      </router-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useUserStore } from "@/store/user";
import { useAuthStore } from "@/store/auth";
import EventBus from "@/eventBus";
import { computed } from "vue";

const user = useUserStore();
const auth = useAuthStore();
const iconStyle = computed(() =>
  user.isInterzero ? "icon-interzero" : "icon-emvia",
);

function toggleOffcanvas() {
  EventBus.emit("hideMenuOffcanvas");
}
</script>

<style scoped>
.active-link {
  background-color: rgb(230, 230, 230);
}
.menu-height {
  height: calc(100vh - 80px);
}

.icon-interzero {
  width: 24px;
  background-color: #9f8855;
}

.icon-emvia {
  width: 24px;
  color: #9f8855;
}

.nav-item:hover {
  background-color: lightgray;
}
</style>
