import { useUserStore } from "@/store/user";
import { useAuthStore } from "@/store/auth";
import { ToastProps, useToast } from "vue-toast-notification";
import EventBus from "@/eventBus";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/vue";
/* eslint-disable no-console */

class Logger {
  private static instance: Logger;
  private toast = useToast({
    duration: 5000,
    onClick() {
      if (useAuthStore().isValid()) EventBus.emit("showFeedbackModal");
    },
  });

  /**
   * Constructs a new Logger instance.
   */
  private constructor() {}

  /**
   * Gets the singleton instance of the Logger class.
   * @returns The singleton instance of the Logger class.
   */
  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  /**
   * Gets the current date and time as a string.
   * @returns A string representing the current date and time.
   */
  private getDateString(): string {
    return `[${new Date().toISOString()}]`;
  }

  /**
   * Constructs a toast message from an error.
   * @param message - The message for the toast message.
   * @param error - The error to construct a toast message from.
   * @returns A toast message string or an empty string if the error message is the same as the message.
   */
  private getToastMessage(message: string, error: any): string {
    let toastMsg = "Unbekannter Fehler";
    try {
      if (error.data?.message) {
        toastMsg = error.data.message;
      } else if (error instanceof AxiosError) {
        toastMsg = error.response?.data?.message ?? error.message;
      } else if (error instanceof Error || error.message) {
        toastMsg = error.message;
      } else if (typeof error === "string") {
        toastMsg = error;
      }
    } catch (getError: any) {
      toastMsg = "Unbekannter Fehler: Fehler konnte nicht ermittelt werden";
      this.err("Fehler beim Ermitteln des Fehlers", getError, { error });
    }
    if (toastMsg === message) return "";
    return toastMsg;
  }

  /**
   * Shows the given information using a toast message. Does not log anything to the console.
   * @param message - The message to show.
   */
  public userSuccess(message: string, toastProps?: ToastProps): void {
    this.toast.success(message, toastProps);
  }

  /**
   * Shows the given information using a toast message.
   * @param info - The information to show.
   */
  public userInfo(info: string, toastProps?: ToastProps): void {
    this.toast.info(info, toastProps);
  }

  /**
   * Shows the given information using a toast message.
   * Alias for userInfo().
   * @param info - The information to show.
   */
  public userLog = this.userInfo;

  /**
   * Logs user warnings to a toast message, console and session recording.
   * @param warning - The warning to log.
   */
  public userWarn(warning: string): void {
    this.toast.warning(warning);
    console.warn(`${this.getDateString()} User Warning: ${warning}`);
    this.sessionWarn(warning);
  }

  /**
   * Logs user errors to a toast message, console and session recording.
   * For the toast message, message and error message (if any) are concatenated with a colon.
   * @param message - The message to log.
   * @param error - The error to log. Use `new Error(message)` if no error is available (needed for an accurate stack trace)
   * @param context - Additional context information to log.
   */
  public userErr(message: string, error: any, context?: any): void {
    const errorMsg = this.getToastMessage(message, error);
    let toastMsg = message;
    if (errorMsg) {
      toastMsg += `: ${errorMsg}`;
    }
    const consoleMsg = `${this.getDateString()} User Error: ${toastMsg}`;
    this.toast.error(toastMsg);
    console.error(consoleMsg, error, context);
    this.sessionErr(message, error, context);
  }

  /**
   * Logs debug information to the console and session recording and shows them as toasts if debug toasts are enabled.
   * Alias for debugInfo().
   * @param message - The information to log.
   * @param context - Additional objects to log to the console.
   */
  public debugLog = this.debugInfo;

  /**
   * Logs debug information to the console and session recording and shows them as toasts if debug toasts are enabled.
   * @param message - The information to log.
   * @param context - Additional objects to log to the console.
   */
  public debugInfo(message: string, context?: any): void {
    const msg = `DEBUG_INFO: ${message}`;
    if (useUserStore().prefs.showDebugToasts) this.toast.info(msg);
    console.info(`${this.getDateString()} ${msg}`, context);
  }

  /**
   * Logs debug warnings to the console and session recording and shows them as toasts if debug toasts are enabled.
   * @param message - The warning to log.
   * @param context - Additional context information to log.
   */
  public debugWarn(message: string, context?: any): void {
    const msg = `DEBUG_WARN: ${message}`;
    if (useUserStore().prefs.showDebugToasts) this.toast.warning(msg);
    console.warn(`${this.getDateString()} ${msg}`, context);
  }

  /**
   * Logs debug errors to the console and session recording and shows them as toasts if debug toasts are enabled.
   * @param message - The message to log.
   * @param error - The error to log. Use `new Error(message)` if no error is available (needed for an accurate stack trace)
   * @param context - Additional context information to log.
   */
  public debugErr(message: string, error: any, context?: any): void {
    const msg = `DEBUG_ERR: ${message}: ${this.getToastMessage(
      message,
      error,
    )}`;
    if (useUserStore().prefs.showDebugToasts) this.toast.error(msg);
    const consoleMsg = `${this.getDateString()} ${msg}`;
    console.error(consoleMsg, error, context);
    this.sessionErr(message, error, context);
  }

  /**
   * Logs general information to the console and to the session recording. Alias for info().
   * @param message - The information to log.
   * @param context - Additional objects to log.
   */
  public log = this.info;

  /**
   * Logs general information to the console and to the session recording.
   * @param message - The information to log.
   * @param context - Additional objects to log.
   */
  public info(message: string, context?: any): void {
    console.info(`${this.getDateString()} INFO: ${message}`, context);
  }

  /**
   * Logs general warnings to the console and to the session recording.
   * @param warnings - The warnings to log.
   */
  public warn(message: string, context?: any): void {
    console.warn(`${this.getDateString()} WARNING: ${message}`, context);
    this.sessionWarn(message, context);
  }

  /**
   * Logs general errors to the console and to the session recording.
   * @param message - The message to log.
   * @param error - The error to log. Use `new Error(message)` if no error is available (needed for an accurate stack trace)
   * @param context - Additional context information to log.
   */
  public err(message: string, error: any, context?: any): void {
    console.error(`${this.getDateString()} ERROR: ${message}`, error, context);
    this.sessionErr(message, error, context);
  }

  /**
   * Log something to the session recording. Alias for sessionInfo().
   * This is not visible to the user and does not show up in the console.
   * @param infos - The information to log.
   */
  public sessionLog = this.sessionInfo;

  /**
   * Log an information to the session recording.
   * This is not visible to the user and does not show up in the console.
   * @param message - The information to log.
   * @param context - Additional objects to log.
   */
  public sessionInfo(message: string, context?: any): void {
    Sentry.captureMessage(message, {
      level: "info",
      extra: {
        infos: context,
      },
    });
  }

  /**
   * Log a warning to the session recording.
   * This is not visible to the user and does not show up in the console.
   * @param message - The information to log.
   * @param context - Additional objects to log.
   */
  public sessionWarn(message: string, context?: any): void {
    Sentry.captureMessage(message, {
      level: "warning",
      extra: {
        warnings: context,
      },
    });
  }

  /**
   * Logs an error to the session recording.
   * This is not visible to the user and does not show up in the console.
   * @param message - The information to log.
   * @param error - The error to log. Use `new Error(message)` if no error is available (needed for an accurate stack trace)
   * @param context - Additional objects to log.
   */
  public sessionErr(message: string, error: any, context?: any): void {
    Sentry.captureException(error, {
      extra: { message, context },
    });
  }
}

const logger = Logger.getInstance();
export default logger;
