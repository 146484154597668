<!-- frontend/src/components/Modals/CustomLogoModal.vue -->
<template>
  <ModalComponent
    name="CustomLogo"
    title="Benutzerdefiniertes Logo für Demozwecke"
    action-button-text="Speichern"
    action-button-color="btn-primary"
    dismiss-button-text="Auf Standard zurücksetzen"
    dismiss-button-color="btn-secondary"
    :action-callback="changeLogo"
    :dismiss-callback="resetLogo"
  >
    <form>
      <div class="mb-3">
        <label for="logo-text" class="form-label"> URL des Logos </label>
        <input
          type="text"
          class="form-control"
          id="logo-text"
          v-model="logoURL"
          placeholder="https://example.com/logo.png"
        />
      </div>
    </form>
    <div class="alert alert-info" role="info">
      Dies ändert das Logo dauerhaft für alle Tabs dieses Browsers. Das neue
      Logo wird nicht auf dem Server gespeichert und ist nur für dich sichtbar.
      Es kann jederzeit mithilfe des linken Buttons gelöscht werden. Hinweis:
      Wenn du ein eigenes Bild verwenden möchtest, nutze einfach einen
      Hosting-Service wie z.B.
      <a href="https://imgbb.com/" target="_blank" rel="noopener">ImgBB</a> um
      einen Link zu erhalten.
    </div>
  </ModalComponent>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { useUserStore } from "@/store/user";
import EventBus from "@/eventBus";
import logger from "@/utils/logging";

const user = useUserStore();
const logoURL = ref<string>(user.customLogo);

const resetLogo = () => {
  logoURL.value = "";
  user.customLogo = "";
  logger.userSuccess("Logo zurückgesetzt");
};

const changeLogo = () => {
  user.customLogo = logoURL.value;
  EventBus.emit("hideUserProfileOffcanvas");
  logger.userSuccess("Logo angepasst");
};
</script>
