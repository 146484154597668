<!-- frontend/src/components/Modals/LogoutModal.vue -->
<template>
  <ModalComponent
    name="Logout"
    title="Abmelden"
    action-button-text="Ja"
    action-button-color="btn-danger"
    dismiss-button-text="Nein"
    dismiss-button-color="btn-secondary"
    text-body="Bist du sicher, dass du dich abmelden möchtest?"
    :action-callback="handleLogout"
  />
</template>

<script setup lang="ts">
import { useAuthStore } from "@/store/auth";
import ModalComponent from "@/components/ModalComponent.vue";
import { useRouter } from "vue-router";

const auth = useAuthStore();
const router = useRouter();

const handleLogout = () => {
  auth.logout();
  router.push("/signin");
};
</script>
