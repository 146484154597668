<!-- frontend/src/components/Modals/PostingModal.vue -->
<template>
  <ModalComponent
    name="XINGPosting"
    title="XING Stellenausschreibung veröffentlichen"
    action-button-text="Posten"
    action-button-color="btn-primary"
    dismiss-button-text="Abbrechen"
    dismiss-button-color="btn-secondary"
    :action-callback="sendPost"
    :modal-close-callback="removeEventListener"
    :modal-open-callback="initialize"
  >
    <form>
      <div class="mb-3">
        <img src="@/assets/xing_logo.svg" class="logo me-3" alt="XING Logo" />
        <FontAwesomeIcon
          :icon="isValidToken ? 'check-circle' : 'exclamation-triangle'"
          class="me-3"
        />
        <span class="me-3">{{ statusMessage }}</span>
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="authorizeUser"
        >
          {{ isValidToken ? "Erneuern" : "Autorisieren" }}
        </button>
        <button
          v-if="isValidToken"
          type="button"
          class="btn btn-outline-danger ms-2"
          @click="removeUser"
        >
          Widerrufen
        </button>
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <label for="post-title" class="form-label">Job Titel</label>
          <InfoTooltip
            text="Der vollständige Job-Titel. Maximal 255 Zeichen."
            class="form-label ms-2"
          />
        </div>
        <input
          class="form-control"
          v-model="postTitle"
          id="post-title"
          maxlength="255"
          minlength="1"
        />
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <label for="post-company-name" class="form-label"
            >Unternehmen Name (Bspw. Emvia Hamburg)</label
          >
          <InfoTooltip
            text="Genauer Unternehmenname, max. 255 Zeichen."
            class="form-label ms-2"
          />
        </div>
        <input
          class="form-control"
          v-model="postCompanyName"
          id="post-company-name"
          maxlength="255"
          minlength="1"
        />
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <label for="post-text" class="form-label">Job Beschreibung</label>
          <InfoTooltip
            text="Beschreibt die Stellenausschreibung."
            class="form-label ms-2"
          />
        </div>
        <textarea
          class="form-control"
          rows="10"
          v-model="postText"
          id="post-text"
        ></textarea>
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <label for="post-city" class="form-label">Stadt/Umfeld</label>
          <InfoTooltip
            text="In welcher Stadt/Umfeld die Stelle ausgeschrieben ist."
            class="form-label ms-2"
          />
        </div>
        <input
          class="form-control"
          v-model="postCity"
          id="post-city"
          minlength="1"
        />
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <label for="post-reply-email" class="form-label"
            >HR Bewerbungs-Email</label
          >
          <InfoTooltip
            text="Die Email Adresse an welche die Bewerbungen gehen."
            class="form-label ms-2"
          />
        </div>
        <input
          type="email"
          class="form-control"
          v-model="postReplyEmail"
          id="post-reply-email"
          minlength="1"
        />
      </div>
    </form>
  </ModalComponent>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import ModalComponent from "@/components/ModalComponent.vue";
import logger from "@/utils/logging";
import axiosInstance from "@/axiosInstance";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import InfoTooltip from "@/components/InfoTooltip.vue";
import { AxiosError } from "axios";
import { useCompletionsStore } from "@/store/completions";
import { formatMessage } from "@/components/TemplateExecution/helpers";
import Prism from "prismjs";

const cStore = useCompletionsStore();
const statusMessage = ref<string>("Token Status wird geladen...");

const postTitle = ref<string>("");
const postCompanyName = ref<string>("");
const postText = ref<string>("");
const postCity = ref<string>("");
const postReplyEmail = ref<string>("info@emvia.de");
let isValidToken = false;

async function authorizeUser() {
  logger.userInfo("Autorisierung wird durchgeführt...");
  document.addEventListener("visibilitychange", handleVisibilityChange);
  const response = await axiosInstance.get("/api/xing/authorize");
  const url = response.data.url;
  window.open(url, "_blank");
}

async function removeUser() {
  try {
    await axiosInstance.delete("/api/xing");
    logger.userSuccess("Autorisierung erfolgreich widerrufen");
    await fetchTokenExpiration();
  } catch (error) {
    logger.userErr("Fehler beim Widerrufen der Autorisierung", error);
  }
}

function initialize() {
  fetchTokenExpiration();
  postText.value =
    cStore.activeCompletion.messages[cStore.selectedMessageIndex].content;
}

async function fetchTokenExpiration() {
  const response = await axiosInstance.post("/api/xing/token");
  if (response.data.accessToken != "") {
    isValidToken = true;
  }
}

async function sendPost() {
  try {
    // Save the edited message
    const oldMessage =
      cStore.activeCompletion.messages[cStore.selectedMessageIndex];
    oldMessage.content = postText.value;
    cStore.activeCompletion.messages[cStore.selectedMessageIndex] =
      await formatMessage(oldMessage);
    nextTick(() => {
      Prism.highlightAll();
    });
    // Send the post
    logger.userInfo(
      "Stellenanzeige wird veröffentlicht, dies kann einige Sekunden dauern...",
    );
    const response = await axiosInstance.post("/api/xing/publish", {
      title: postTitle.value,
      company_name: postCompanyName.value,
      description: postText.value,
      city: postCity.value,
      reply_email: postReplyEmail.value,
    });
    logger.userSuccess(
      "Stellenazeige veröffentlicht! Klicke hier um ihn anzusehen",
      {
        duration: 10000,
        onClick: () => {
          window.open(response.data.location, "_blank", "noopener noreferrer");
        },
      },
    );
  } catch (error) {
    logger.userErr("Fehler beim Veröffentlichen", error);
  }
}

function removeEventListener() {
  document.removeEventListener("visibilitychange", handleVisibilityChange);
}

async function handleVisibilityChange() {
  if (!document.hidden) {
    // User returned to the tab
    await fetchTokenExpiration();
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    if (!isValidToken) {
      const msg = "Autorisierung fehlgeschlagen, bitte erneut versuchen";
      logger.userErr(msg, new Error(msg));
      return;
    }
    logger.userSuccess("Autorisierung erfolgreich");
  }
}
</script>

<style scoped>
.logo {
  width: 24px;
}
</style>
