import { format } from "date-fns";
import { de } from "date-fns/locale";
import { version as frontendVersion } from "../../../package.json";
import { SemVer } from "semver";
import EventBus from "@/eventBus";
import logger from "@/utils/logging";

/**
 * Format date to dd.MM.yyyy HH:mm:ss using a non-breaking space between date and time
 * @param date Date to format
 * @returns Formatted date or "N/A" if date is undefined
 */
export function formatDate(date: string) {
  if (!date) {
    return "N/A";
  }
  return format(new Date(date), "dd.MM.yyyy\xa0HH:mm:ss", {
    locale: de,
  });
}

/**
 * Check if the backend version is ahead of the frontend version.
 * If so, show a modal to the user to refresh the page.
 */
export function checkForMajorVersionUpdate(backendVersion: string) {
  if (
    backendVersion &&
    new SemVer(backendVersion).major > new SemVer(frontendVersion).major
  ) {
    logger.debugInfo(
      `Backend version ${backendVersion} is ahead of frontend version ${frontendVersion}`,
    );
    EventBus.emit("showUpdateModal");
  }
}
