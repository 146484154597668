// src/store/cache.ts
import { defineStore } from "pinia";
import { IAIModel } from "@backend/models/AIModel";
import axiosInstance from "@/axiosInstance";
import logger from "@/utils/logging";

export const useCacheStore = defineStore({
  id: "cache",
  state: () => ({
    AIModels: undefined as IAIModel[] | undefined,
    isInitialized: false,
  }),
  actions: {
    async initialize() {
      await this.fetchAIModels();
      this.isInitialized = true;
    },
    getAIModel(id: string): IAIModel | undefined {
      return this.AIModels?.find((model) => model.id === id);
    },
    destroy(): void {
      this.isInitialized = false;
    },
    async fetchAIModels() {
      try {
        const response = await axiosInstance.get("/api/models");
        this.AIModels = response.data;
      } catch (error) {
        logger.debugErr("KI-Modell-Liste konnte nicht geladen werden", error);
      }
    },
  },
});
