<!-- frontend/src/components/ModalComponent.vue -->
<template>
  <div
    class="modal fade"
    :class="classes"
    ref="modalComponent"
    tabindex="-1"
    aria-labelledby="modalComponentLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalComponentLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
          {{ textBody ? textBody : "" }}
        </div>
        <div class="modal-footer" v-if="dismissButtonText || actionButtonText">
          <button
            v-if="dismissButtonText"
            type="button"
            class="btn"
            :class="dismissButtonColor"
            data-bs-dismiss="modal"
            @click="dismissCallback"
          >
            {{ dismissButtonText }}
          </button>
          <button
            v-if="actionButtonText"
            type="button"
            class="btn"
            :class="actionButtonColor"
            data-bs-dismiss="modal"
            @click="actionCallback"
          >
            {{ actionButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, Ref, PropType } from "vue";
import { Modal } from "bootstrap";
import EventBus from "@/eventBus";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  classes: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    required: true,
  },
  actionButtonText: {
    type: String,
    default: "",
  },
  actionButtonColor: {
    type: String,
    default: "btn-danger",
  },
  dismissButtonText: {
    type: String,
    default: "",
  },
  dismissButtonColor: {
    type: String,
    default: "btn-secondary",
  },
  textBody: {
    type: String,
    default: "",
  },
  actionCallback: {
    type: Function as PropType<(eventPayload?: MouseEvent) => unknown | void>,
    default: () => {},
  },
  dismissCallback: {
    type: Function as PropType<(eventPayload?: MouseEvent) => unknown | void>,
    default: () => {},
  },
  modalOpenCallback: {
    type: Function as PropType<() => unknown | void>,
    default: () => {},
  },
  modalCloseCallback: {
    type: Function as PropType<() => unknown | void>,
    default: () => {},
  },
  backdrop: {
    type: [Boolean, String] as PropType<"static" | boolean>,
    default: true,
  },
});

const modalComponent: Ref<HTMLElement | null> = ref(null);
defineExpose({ modalComponent });
let modalInstance: Modal | null = null;

onMounted(() => {
  modalInstance = new Modal(modalComponent.value as HTMLElement, {
    keyboard: false,
    backdrop: props.backdrop,
  });
  EventBus.on(`show${props.name}Modal`, () => {
    modalInstance!.show();
  });
  EventBus.on(`hide${props.name}Modal`, () => {
    modalInstance!.hide();
  });
  // Add event listener for 'hidden.bs.modal' event
  modalComponent.value?.addEventListener("hidden.bs.modal", () => {
    props.modalCloseCallback();
  });
  // Add event listener for 'show.bs.modal' event
  modalComponent.value?.addEventListener("show.bs.modal", () => {
    props.modalOpenCallback();
  });
});

onUnmounted(() => {
  EventBus.off(`show${props.name}Modal`);
  EventBus.off(`hide${props.name}Modal`);
  modalInstance!.dispose();
});
</script>
