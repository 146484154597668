<!-- frontend/src/components/Modals/PostingModal.vue -->
<template>
  <ModalComponent
    name="Posting"
    title="LinkedIn Post veröffentlichen"
    action-button-text="Posten"
    action-button-color="btn-primary"
    dismiss-button-text="Abbrechen"
    dismiss-button-color="btn-secondary"
    :action-callback="sendPost"
    :modal-close-callback="removeEventListener"
    :modal-open-callback="initialize"
  >
    <form>
      <div class="mb-3">
        <img
          src="@/assets/linkedin_logo.svg"
          class="logo me-3"
          alt="LinkedIn Logo"
        />
        <FontAwesomeIcon
          :icon="isValidToken ? 'check-circle' : 'exclamation-triangle'"
          class="me-3"
        />
        <span class="me-3">{{ statusMessage }}</span>
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="authorizeUser"
        >
          {{ isValidToken ? "Erneuern" : "Autorisieren" }}
        </button>
        <button
          v-if="isValidToken"
          type="button"
          class="btn btn-outline-danger ms-2"
          @click="removeUser"
        >
          Widerrufen
        </button>
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <label for="templateModel" class="form-label">Posten als</label>
          <InfoTooltip
            text="Wähle aus, ob du als Privatperson oder als Unternehmen (falls verfügbar) posten möchtest."
            class="form-label ms-2"
          />
        </div>
        <select v-model="selectedAuthor" class="form-select">
          <option
            v-for="(author, index) in authors"
            :key="index"
            :value="author.id"
          >
            {{ author.name }}
          </option>
        </select>
      </div>
      <div class="mb-3" v-if="postImageURL">
        <div class="d-flex align-items-center">
          <label for="post-image" class="form-label">Bild</label>
          <InfoTooltip
            text="Das aktuell ausgewählte Bild wird für deinen Post verwendet. Wenn du kein Bild verwenden möchtest, entferne das generierte Bild zuerst in der Nachrichtenübersicht."
            class="form-label ms-2"
          />
        </div>
        <img
          :src="postImageURL"
          alt="Generiertes Bild"
          class="img-fluid"
          id="post-image"
        />
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <label for="post-text" class="form-label">Text</label>
          <InfoTooltip
            text="Der Text wird für deinen Post verwendet. Du kannst ihn hier noch bearbeiten. Bitte beachte das Personenmarkierungen (@Person) hier nicht möglich sind."
            class="form-label ms-2"
          />
        </div>
        <textarea
          class="form-control"
          rows="10"
          v-model="postText"
          id="post-text"
        ></textarea>
      </div>
    </form>
  </ModalComponent>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import ModalComponent from "@/components/ModalComponent.vue";
import logger from "@/utils/logging";
import axiosInstance from "@/axiosInstance";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import InfoTooltip from "@/components/InfoTooltip.vue";
import { AxiosError } from "axios";
import { useCompletionsStore } from "@/store/completions";
import { formatMessage } from "@/components/TemplateExecution/helpers";
import Prism from "prismjs";

const cStore = useCompletionsStore();
const statusMessage = ref<string>("Token Status wird geladen...");
const authors = ref([
  {
    name: "Wird geladen...",
    id: "1234",
  },
]);
const selectedAuthor = ref<string>("1234");
const postImageURL = ref<string>("");
const postText = ref<string>("");
const now = new Date();
const expirationDate = ref<Date>(new Date());
const isValidToken = computed(() => {
  return expirationDate.value > now;
});

async function authorizeUser() {
  logger.userInfo("Autorisierung wird durchgeführt...");
  document.addEventListener("visibilitychange", handleVisibilityChange);
  const response = await axiosInstance.get("/api/linkedin/authorize");
  const url = response.data.url;
  window.open(url, "_blank");
}

async function removeUser() {
  try {
    await axiosInstance.delete("/api/linkedin");
    logger.userSuccess("Autorisierung erfolgreich widerrufen");
    await fetchTokenExpiration();
  } catch (error) {
    logger.userErr("Fehler beim Widerrufen der Autorisierung", error);
  }
}

function initialize() {
  fetchTokenExpiration();
  if (cStore.activeCompletion.image_data) {
    postImageURL.value =
      cStore.activeCompletion.image_data[cStore.activeImageIndex].imageUrl;
  } else {
    postImageURL.value = "";
  }
  postText.value =
    cStore.activeCompletion.messages[cStore.selectedMessageIndex].content;
}

async function fetchTokenExpiration() {
  try {
    const response = await axiosInstance.get("/api/linkedin/status");
    authors.value = response.data.authors;
    authors.value.sort((a, b) => {
      return b.id.localeCompare(a.id);
    });
    selectedAuthor.value = authors.value[0].id;
    expirationDate.value = parseISO(response.data.refreshTokenExpiresAt);
    const formattedDate = format(expirationDate.value, "Pp", { locale: de });
    statusMessage.value = `Autorisierung gültig bis ${formattedDate}`;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      statusMessage.value = "Autorisierung erforderlich";
      expirationDate.value = now;
      authors.value[0].name = "Bitte zuerst autorisieren";
      authors.value.splice(1);
      return;
    }
    statusMessage.value = "Fehler beim Laden der Statusinformationen";
    authors.value[0].name = "Fehler beim Laden der Autoren";
    authors.value.splice(1);
  }
}

async function sendPost() {
  try {
    // Save the edited message
    const oldMessage =
      cStore.activeCompletion.messages[cStore.selectedMessageIndex];
    oldMessage.content = postText.value;
    const newMessage = await formatMessage(oldMessage);
    cStore.activeCompletion.messages[cStore.selectedMessageIndex] = newMessage;
    nextTick(() => {
      Prism.highlightAll();
    });
    // Send the post
    logger.userInfo(
      "Post wird veröffentlicht, dies kann einige Sekunden dauern...",
    );
    const response = await axiosInstance.post("/api/linkedin/publish", {
      text: postText.value,
      imageURL: postImageURL.value,
      author: selectedAuthor.value,
    });
    logger.userSuccess("Post veröffentlicht! Klicke hier um ihn anzusehen", {
      duration: 10000,
      onClick: () => {
        window.open(response.data.location, "_blank", "noopener noreferrer");
      },
    });
  } catch (error) {
    logger.userErr("Fehler beim Veröffentlichen", error);
  }
}

function removeEventListener() {
  document.removeEventListener("visibilitychange", handleVisibilityChange);
}

async function handleVisibilityChange() {
  if (!document.hidden) {
    // User returned to the tab
    await fetchTokenExpiration();
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    if (!isValidToken.value) {
      const msg = "Autorisierung fehlgeschlagen, bitte erneut versuchen";
      logger.userErr(msg, new Error(msg));
      return;
    }
    logger.userSuccess("Autorisierung erfolgreich");
  }
}
</script>

<style scoped>
.logo {
  width: 24px;
}
</style>
