<!-- frontend/src/components/UserProfileOffcanvas.vue -->
<template>
  <div>
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">
          Profilinformationen
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="mb-3">
          <h6>Angemeldet als</h6>
          <p class="text-muted m-0">
            {{ user.profile.name }} ({{ user.profile.email }})
          </p>
          <p class="text-muted m-0" v-if="user.profile.role.name === 'admin'">
            Sitzungstoken gültig bis
            {{ auth.expirationTime.toLocaleTimeString() }}
          </p>
        </div>
        <div class="card mb-3" v-if="user.perms.useAllModules">
          <div class="card-body">
            <div class="d-grid gap-2">
              <div>
                <h6>Aktuelle Nutzung</h6>
                <div class="progress position-relative mb-3">
                  <div
                    class="progress-bar bg-info"
                    role="progressbar"
                    :style="{ width: usagePercentage + '%' }"
                    :aria-valuenow="usagePercentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <p
                    class="justify-content-center d-flex position-absolute w-100"
                    v-if="user.perms.viewInternalModules"
                  >
                    {{ user.profile.usage.toFixed(2) }} von
                    {{ user.profile.limit }}$
                  </p>
                  <p
                    class="justify-content-center d-flex position-absolute w-100"
                    v-else
                  >
                    {{ usagePercentage }}%
                  </p>
                </div>
                <p
                  class="text-muted m-0"
                  v-if="user.profile.role.monthlyUsageLimitReset"
                >
                  Zurücksetzung am {{ resetDate }}
                </p>
                <p
                  class="text-muted m-0"
                  v-if="user.daysUntilExpiration !== -1"
                >
                  Testversion: {{ user.daysUntilExpiration }} Tage verbleibend
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <div class="d-grid gap-2">
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="openFeedbackModal"
              >
                Feedback senden / Bug melden
              </button>
              <button
                v-if="user.perms.viewInternalModules"
                class="btn btn-outline-secondary"
                type="button"
                @click="openCustomLogoModal"
              >
                Logo anpassen
              </button>
              <button
                v-if="user.profile.role.name === 'admin'"
                class="btn btn-outline-primary"
                type="button"
                @click="
                  () =>
                    user.updateSettings({
                      showDebugToasts: !user.prefs.showDebugToasts,
                    })
                "
              >
                Debug-Infotoasts
                {{ user.prefs.showDebugToasts ? "ausschalten" : "einschalten" }}
              </button>
              <button
                v-if="user.profile.role.name === 'admin'"
                class="btn btn-outline-primary"
                type="button"
                @click="refreshToken"
              >
                Token erneuern
              </button>
              <button
                v-if="user.profile.role.name === 'admin'"
                class="btn btn-outline-primary"
                type="button"
                @click="changeDesign"
              >
                Design wechseln
              </button>
              <button
                class="btn btn-outline-danger"
                type="button"
                @click="openLogoutModal"
              >
                Abmelden
              </button>
            </div>
          </div>
        </div>
        <div class="card" v-if="user.perms.viewStats">
          <div class="card-body">
            <div class="d-grid gap-2">
              <div>Midjourney-Infos:</div>
              <table
                class="table table-striped table-bordered"
                v-if="mjStats"
                aria-describedby="mjStatsDesc"
              >
                <thead class="visually-hidden">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Aktueller Wert</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, key) in mjStats"
                    :key="key"
                    class="table-light"
                  >
                    <td class="font-weight-bold">{{ key }}</td>
                    <td>{{ value }}</td>
                  </tr>
                </tbody>
              </table>

              <div v-else>
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  @click="updateMjStats"
                >
                  Statistiken laden
                </button>
              </div>
              <div id="mjStatsDesc" class="visually-hidden">
                This table presents midjourney statistics.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end mt-auto text-muted small me-1">
        Version {{ version }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Offcanvas } from "bootstrap";
import EventBus from "@/eventBus";
import { useUserStore } from "@/store/user";
import { format, endOfMonth } from "date-fns";
import { de } from "date-fns/locale";
import { ref, onBeforeUnmount, onMounted, computed } from "vue";
import { IMidjourneyStats } from "@backend/routes/admin/statistics";
import axiosInstance from "@/axiosInstance";
import logger from "@/utils/logging";
import { useAuthStore } from "@/store/auth";
import { version } from "../../../package.json";

const user = useUserStore();
const auth = useAuthStore();

const refreshToken = async () => {
  try {
    await auth.requestToken(false, true, true);
  } catch (error) {
    logger.userErr("Fehler beim Erneuern des Tokens", error);
  }
};

const resetDate = computed(() => {
  return format(endOfMonth(new Date()), "dd.MM.yyyy", {
    locale: de,
  });
});
const usagePercentage = computed(() => {
  return user.profile.limit === 0
    ? 0
    : Math.round((user.profile.usage / user.profile.limit) * 100);
});

onBeforeUnmount(() => {
  EventBus.off("showUserProfileOffcanvas");
});

onMounted(async () => {
  const myOffcanvas = document.getElementById("offcanvasRight");
  if (!myOffcanvas) throw new Error("Offcanvas element not found");
  const bsOffcanvas = new Offcanvas(myOffcanvas, {
    keyboard: false,
  });

  EventBus.on("showUserProfileOffcanvas", async () => {
    bsOffcanvas.show();
    await user.fetchProfile();
  });
  EventBus.on("hideUserProfileOffcanvas", () => {
    bsOffcanvas.hide();
  });
});

const mjStats = ref<IMidjourneyStats>();
async function updateMjStats() {
  try {
    mjStats.value = { "Statistiken werden geladen...": "" } as any;
    const response = await axiosInstance.get(
      "/api/admin/statistics/midjourney",
    );
    mjStats.value = response.data;
  } catch (error) {
    logger.userErr(
      "Fehler beim Aktualisieren der Midjourney-Statistiken",
      error,
    );
  }
}

function openFeedbackModal() {
  EventBus.emit("showFeedbackModal");
}
function openLogoutModal() {
  EventBus.emit("showLogoutModal");
}
function openCustomLogoModal() {
  EventBus.emit("showCustomLogoModal");
}
function changeDesign() {
  user.isInterzero = !user.isInterzero;
  user.pageTitle = user.isInterzero ? "Interzero AI" : "Emvia AI";
  document.title = `${user.pageTitle} - ${document.title.split("-")[1]}`;
  // Change the theme based on the user's preference.
  const bodyClassList = document.body.classList;
  bodyClassList.remove("interzero-theme", "emvia-theme");
  bodyClassList.add(user.isInterzero ? "interzero-theme" : "emvia-theme");
}
</script>

<style scoped>
.btn-outline-light img {
  border: 1px solid #fff;
}
</style>
