// src/store/user.ts
import { defineStore } from "pinia";
import { differenceInCalendarDays, addDays, startOfMonth } from "date-fns";
import axiosInstance from "@/axiosInstance";
import logger from "@/utils/logging";
import { useLocalStorage } from "@vueuse/core";
import { UserProfile } from "@backend/routes/users";
import { version } from "../../../package.json";
import { SemVer } from "semver";
import * as Sentry from "@sentry/vue";

const IS_INTERZERO_DOMAIN = window.location.origin.includes("interzero");

const defaultProfile: Readonly<UserProfile> = {
  limit: 0,
  expiresAt: addDays(new Date(), 7),
  usage: 0,
  name: "Wird geladen...",
  email: "E-Mail-Adresse wird geladen...",
  createdAt: new Date(),
  updatedAt: new Date(),
  favoriteTemplates: [] as string[],
  purchasedModules: [] as string[],
  tryModulesLimit: 0,
  triedModulesCount: {} as { [key: string]: number | undefined },
  role: {
    name: "Wird geladen...",
    description: "Wird geladen...",
    usageLimit: 0,
    timeLimit: 0,
    tryModulesLimit: 0,
    includedModules: [] as string[],
    monthlyUsageLimitReset: false,
    domains: [],
    permissions: {
      viewStats: false,
      viewPrompts: false,
      viewInternalModules: false,
      viewPublicModules: false,
      useAllModules: false,
      createModules: false,
      manageUsers: false,
      manageAutomations: false,
      manageModules: false,
      manageCategories: false,
      manageEmailTemplates: false,
    },
  },
  settings: {
    showPrompt: false,
    privacyPopupAccepted: {
      version: 0,
      acceptedAt: new Date().toISOString(),
    },
    showTokens: false,
    showInfo: false,
    showDebugToasts: false,
    useStreaming: true,
    limitWarningDisplayed: {
      version: 0,
      displayedAt: new Date().toISOString(),
    },
  },
};

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    isInitialized: false,
    profile: useLocalStorage("profileV2", defaultProfile, {
      mergeDefaults: true,
    }),
    historyBreadcrumbs: [{ text: "Startseite", href: "/" }],
    customLogo: useLocalStorage("customLogo", ""),
    isInterzero: IS_INTERZERO_DOMAIN,
    pageTitle: IS_INTERZERO_DOMAIN ? "Interzero AI" : "Emvia AI",
    appVersion: useLocalStorage("appVersion", "1.0.0"),
  }),
  getters: {
    includedModules: (state): Readonly<string[]> =>
      [
        state.profile.purchasedModules,
        state.profile.role.includedModules,
      ].flat(),
    perms: (state): Readonly<UserProfile["role"]["permissions"]> =>
      state.profile.role.permissions,
    prefs: (state): Readonly<UserProfile["settings"]> => state.profile.settings,
    daysUntilExpiration: (state): Readonly<number> => {
      if (!state.profile.expiresAt) {
        return -1;
      }
      const diff = differenceInCalendarDays(
        new Date(state.profile.expiresAt),
        new Date(),
      );
      if (diff < 0) {
        return 0;
      }
      return diff;
    },
  },
  actions: {
    async initialize(): Promise<void> {
      await this.fetchProfile();
      const userData = {
        email: this.profile.email,
        role: this.profile.role.name,
        limit: this.profile.limit,
        usage: this.profile.usage,
        currentVersion: version,
        previousVersion: this.appVersion,
      };
      Sentry.setUser({
        ...userData,
        username: this.profile.name,
        id: this.profile.email,
      });

      // Migrate profile
      if (
        new SemVer(this.appVersion).compare("1.7.0") === -1 &&
        new SemVer(version).compare("1.7.0") !== -1
      ) {
        logger.info(
          `Migrating profile from version ${this.appVersion} to ${version}`,
        );
        const oldSettings = {
          showPrompt: localStorage.getItem("includePrompt") === "true",
          privacyPopupAccepted: {
            version:
              localStorage.getItem("privacyPopupAccepted") === "true" ? 1 : 0,
            acceptedAt: new Date().toISOString(),
          },
          showTokens: localStorage.getItem("showTokens") === "true",
          showInfo: localStorage.getItem("showInfo") === "true",
          showDebugToasts: localStorage.getItem("showDebugToasts") === "true",
          useStreaming:
            localStorage.getItem("useStreaming") === "false" ? false : true,
          limitWarningDisplayed: {
            version:
              localStorage.getItem("limitWarningDisplayed") === "true" ? 1 : 0,
            displayedAt: new Date().toISOString(),
          },
        };
        await this.updateSettings(oldSettings);
        localStorage.removeItem("includePrompt");
        localStorage.removeItem("privacyPopupAccepted");
        localStorage.removeItem("showTokens");
        localStorage.removeItem("showInfo");
        localStorage.removeItem("showDebugToasts");
        localStorage.removeItem("useStreaming");
        localStorage.removeItem("limitWarningDisplayed");
      }
      this.appVersion = version;
      this.isInitialized = true;
    },
    async updateSettings(
      settings: Partial<UserProfile["settings"]>,
    ): Promise<void> {
      try {
        await axiosInstance.post("/api/user/settings", {
          ...this.prefs,
          ...settings,
        });
        await this.fetchProfile();
      } catch (error) {
        logger.userErr("Einstellungen konnten nicht gespeichert werden", error);
      }
    },
    async fetchProfile(): Promise<void> {
      try {
        const response = await axiosInstance.get("/api/user/profile");
        this.profile = response.data;
      } catch (error) {
        logger.userErr("Profil konnte nicht geladen werden", error);
      }
    },
    async addFavoriteTemplate(templateId: string): Promise<void> {
      try {
        await axiosInstance.post("/api/user/favorites/" + templateId);
        await this.fetchProfile();
        logger.userSuccess("Favorit hinzugefügt");
      } catch (error) {
        logger.userErr("Favorit konnte nicht hinzugefügt werden", error);
      }
    },
    async removeFavoriteTemplate(templateId: string): Promise<void> {
      try {
        if (!this.profile) {
          const msg = "No profile set, cannot remove favorite";
          return logger.debugErr(msg, new Error(msg));
        }
        await axiosInstance.delete("/api/user/favorites/" + templateId);
        await this.fetchProfile();
        logger.userSuccess("Favorit entfernt");
      } catch (error) {
        logger.userErr("Favorit konnte nicht entfernt werden", error);
      }
    },
    setHistoryBreadcrumbs(breadcrumbs: { text: string; href: string }[]): void {
      this.historyBreadcrumbs = breadcrumbs;
    },
    getHistoryBreadcrumbs(): { text: string; href: string }[] {
      return this.historyBreadcrumbs;
    },
    async setLimitWarningDisplayed(version: number): Promise<void> {
      const limitWarningDisplayed = {
        version,
        displayedAt: new Date().toISOString(),
      };
      await this.updateSettings({ limitWarningDisplayed });
    },
    isLimitWarningDisplayed(version: number): boolean {
      if (this.prefs.limitWarningDisplayed.version < version) {
        return false;
      }
      return (
        startOfMonth(new Date()) <
        new Date(this.prefs.limitWarningDisplayed.displayedAt)
      );
    },
    async setPrivacyPopupAccepted(version: number): Promise<void> {
      const privacyPopupAccepted = {
        version,
        acceptedAt: new Date().toISOString(),
      };
      await this.updateSettings({ privacyPopupAccepted });
    },
    destroy(): void {
      this.historyBreadcrumbs = [{ text: "Startseite", href: "/" }];
      this.isInitialized = false;
    },
  },
});
