<!-- frontend/src/components/Modals/UpdateModal.vue -->
<template>
  <ModalComponent
    name="Update"
    title="Update verfügbar"
    action-button-text="Jetzt neuladen"
    action-button-color="btn-primary"
    dismiss-button-text="Später"
    dismiss-button-color="btn-secondary"
    :action-callback="reload"
  >
    <p>Gute Nachrichten! Unsere Anwendung hat ein Update erhalten ✨</p>
    <p>
      Um sicherzustellen, dass du alle Fehlerbehebungen und Vorteile der neusten
      Version genießen kannst, bitten wir dich, alle ausstehenden Änderungen zu
      sichern und die Seite anschließend neu zu laden.
    </p>
  </ModalComponent>
</template>

<script setup lang="ts">
import ModalComponent from "@/components/ModalComponent.vue";

const reload = () => window.location.reload();
</script>
