<!-- frontend/src/components/Modals/SearchModal.vue -->
<template>
  <div
    class="modal fade"
    ref="searchModal"
    tabindex="-1"
    aria-labelledby="searchModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body p-0">
          <form @submit.prevent="searchTemplates">
            <div class="d-flex px-3 py-2 align-items-center">
              <div class="me-1">
                <FontAwesomeIcon icon="search" />
              </div>
              <input
                id="search-query"
                class="form-control"
                style="border: 0; box-shadow: none"
                placeholder="Module durchsuchen"
                type="text"
                v-model="searchQuery"
                @input="onSearchInput"
              />
            </div>
          </form>
          <div>
            <div
              v-for="(template, index) in searchResults"
              :key="template.uuid"
              class="position-relative d-flex px-3 py-3 border-top hover-row"
              @click="selectTemplate(template)"
              @keydown.enter.prevent="selectTemplate(template)"
              @keydown.tab.prevent="focusNextResult(index)"
              tabindex="0"
              :data-result-index="index"
            >
              <div class="me-3 fs-5">
                <img v-if="template.icon && isSVG(template.icon)" :src="template.icon && getSVGSource(template.icon!)" alt="Icon" style="width: 40px !important;">
                <template v-else>
                  <div v-html="template.icon"></div>
                </template>
              </div>
              <div>
                <div class="fw-semibold">
                  {{ template.title }}
                </div>
                <small class="text-muted">
                  {{ template.description }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, Ref } from "vue";
import axiosInstance from "@/axiosInstance";
import logger from "@/utils/logging";
import { Modal } from "bootstrap";
import { useRouter } from "vue-router";
import EventBus from "@/eventBus";
import { ITemplate } from "@backend/models/Template";

const searchModalActive = ref(false);
const searchQuery = ref("");
const searchModal: Ref<HTMLElement | null> = ref(null);
let searchModalInstance: Modal | null = null;

const searchResults = ref<Partial<ITemplate>[]>([]);

const router = useRouter();

const isSVG = (icon: string): boolean => {
  // Check if the icon data starts with "<svg"
  return !!icon && icon.trim().startsWith("<svg");
};

const getSVGSource = (icon: string): string => {
  // Return the SVG data URI
  return `data:image/svg+xml;base64,${btoa(icon)}`;
};

onMounted(() => {
  searchModalInstance = new Modal(searchModal.value as HTMLElement);
  if (!searchModalInstance) {
    throw new Error("Could not create search modal instance");
  }

  EventBus.on("showSearchModal", () => {
    searchModalActive.value = true;
    searchModalInstance?.show();
  });

  // Add event listener for 'hidden.bs.modal' event
  searchModal.value?.addEventListener("hidden.bs.modal", () => {
    searchQuery.value = "";
    searchResults.value = [];
  });

  // Add event listener for 'shown.bs.modal' event
  searchModal.value?.addEventListener("shown.bs.modal", () => {
    document.getElementById("search-query")?.focus();
  });
});

onBeforeUnmount(() => {
  EventBus.off("showSearchModal");
  if (searchModalInstance) {
    searchModalInstance.dispose();
  }

  // Remove event listener for 'hidden.bs.modal' event
  searchModal.value?.removeEventListener("hidden.bs.modal", () => {
    searchQuery.value = "";
    searchResults.value = [];
  });

  // Remove event listener for 'shown.bs.modal' event
  searchModal.value?.removeEventListener("shown.bs.modal", () => {
    document.getElementById("search-query")?.focus();
  });
});

const focusNextResult = (currentIndex: number) => {
  const nextIndex = (currentIndex + 1) % searchResults.value.length;
  const nextResultElement = document.querySelector(
    `[data-result-index="${nextIndex}"]`,
  ) as HTMLDivElement;
  if (nextResultElement) {
    nextResultElement.focus();
  }
};

const searchTemplates = async () => {
  try {
    const response = await axiosInstance.get("/api/templates/search", {
      params: {
        q: searchQuery.value,
      },
    });
    searchResults.value = response.data;
  } catch (error) {
    logger.userErr("Fehler bei der Suche", error);
  }
};

const onSearchInput = () => {
  if (searchQuery.value.length >= 3) {
    searchTemplates();
  }
};

const selectTemplate = (template: Partial<ITemplate>) => {
  router.push("/templates/" + template.uuid);
  searchModalInstance?.hide();
  searchModalActive.value = false;
};
</script>

<style>
.hover-row {
  cursor: pointer;
}

.hover-row:hover {
  background-color: #f8f9fa;
}
</style>
