<!-- frontend/src/components/InfoTooltip.vue -->
<template>
  <div
    ref="info"
    data-bs-toggle="tooltip"
    :data-bs-placement="props.placement"
    :data-bs-title="props.text"
  >
    <FontAwesomeIcon icon="info-circle" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { Tooltip } from "bootstrap";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  placement: {
    type: String,
    default: "right",
  },
});

const info = ref(null);
const tooltip = ref<Tooltip | null>(null);

onMounted(() => {
  tooltip.value = new Tooltip(info.value!);
});
</script>
