<!--frontend/src/App.vue -->
<template>
  <div class="app-container">
    <SidebarMenu />
    <MainNavbar />
    <MainContent />
    <UserProfileOffcanvas />
    <AllModals />
    <Impressum />
  </div>
</template>

<script setup lang="ts">
import MainNavbar from "@/components/MainNavbar.vue";
import SidebarMenu from "@/components/SidebarMenu.vue";
import MainContent from "@/components/MainContent.vue";
import AllModals from "@/components/Modals/AllModals.vue";
import UserProfileOffcanvas from "@/components/UserProfileOffcanvas.vue";
import Impressum from "@/components/Impressum.vue";

import { initializeCodeHighlighting } from "@/highlighting";
initializeCodeHighlighting();

import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { useSocketStore } from "@/store/socket";
import { useUserStore } from "@/store/user";
import { useCacheStore } from "@/store/cache";
import { msalInstance, handleAuthenticationResult } from "@/auth";
import { computed, onMounted } from "vue";
import logger from "@/utils/logging";
import { useFavicon } from "@vueuse/core";

const router = useRouter();
const auth = useAuthStore();
const user = useUserStore();
// URL based themes
document.title = user.pageTitle;
const favicon = computed(() =>
  user.isInterzero ? "/favicon-interzero.ico" : "/favicon.ico",
);
useFavicon(favicon);
const bodyClassList = document.body.classList;
bodyClassList.remove("interzero-theme", "emvia-theme");
bodyClassList.add(user.isInterzero ? "interzero-theme" : "emvia-theme");

const cache = useCacheStore();
const socket = useSocketStore();

// Debugging
logger.info("Page load started");
logger.info("Existing auth account:", auth.account);
logger.info("Existing auth token:", !!auth.token);
logger.info(
  "Existing auth token expiration:",
  auth.expirationTime.toISOString(),
);
let authChanged = false;

onMounted(async () => {
  // Check if we have just returned from signing in
  try {
    await msalInstance.initialize();
    const response = await msalInstance.handleRedirectPromise();
    if (response) {
      logger.info("Auth redirect detected, handling response...");
      await handleAuthenticationResult(response, router);
      logger.userSuccess("Anmeldung erfolgreich");
      authChanged = true; // debug
    } else if (!auth.isValid() && auth.account) {
      // Get a (new) token (silently if possible)
      await auth.requestToken(false, false, false);
      authChanged = true; // debug
    }
  } catch (err) {
    logger.userErr("Fehler beim Anmelden, bitte versuche es erneut", err);
    user.destroy();
    router.push("/signin");
  }
  if (!auth.account && window.location.pathname !== "/signin") {
    router.push("/signin");
    auth.setInitializationFinished();
  } else if (auth.account) {
    auth.setInitializationFinished();
    await socket.initialize();
    await user.initialize();
    await cache.initialize();
  }
  // Debugging
  if (authChanged) {
    logger.info("New auth account:", auth.account);
    logger.info("New auth token:", !!auth.token);
    logger.info(
      "New auth token expiration:",
      auth.expirationTime.toISOString(),
    );
  }
});
</script>

<style>
.app-container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  background-color: #fbfbfb;
}

a:hover {
  /* Remove ugly green bootstrap hover color */
  background-color: unset !important;
}
</style>
