// frontend/src/auth.ts
import {
  PublicClientApplication,
  AccountInfo,
  SilentRequest,
  RedirectRequest,
  AuthenticationResult,
  BrowserCacheLocation,
  EndSessionRequest,
  CacheLookupPolicy,
} from "@azure/msal-browser";
import { Account, useAuthStore } from "@/store/auth";
import { Router } from "vue-router";
import logger from "@/utils/logging";

const scopes: Array<string> = ["openid", "offline_access", "profile", "email"];

export async function acquireTokenSilent(force = false): Promise<void> {
  const account: AccountInfo | null = getAccount();
  const authStore = useAuthStore();
  if (!account) {
    throw new Error("User is not logged in");
  }
  const tokenRequest: SilentRequest = {
    scopes,
    account,
    cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken,
  };

  if (force) {
    tokenRequest.cacheLookupPolicy = CacheLookupPolicy.RefreshToken;
  }

  logger.info("Acquiring token silently", tokenRequest);

  const response: AuthenticationResult =
    await msalInstance.acquireTokenSilent(tokenRequest);
  if (response.idToken) {
    authStore.setToken(response.idToken);
  }
  if (!response.account?.idTokenClaims?.exp) {
    throw new Error("Token does not contain expiration time");
  }
  msalInstance.setActiveAccount(response.account);
  authStore.setUser(response.account as Account);
  authStore.startTokenRefreshTimeout();
}

export async function acquireTokenRedirect(
  state: string,
  domainHint?: string,
): Promise<void> {
  try {
    const account: AccountInfo | null = getAccount();
    if (!account) {
      throw new Error("User is not logged in");
    }

    const tokenRequest: RedirectRequest = {
      scopes,
      account,
      state,
      ...(domainHint && { domainHint }),
    };
    logger.info("Acquiring token with redirect", tokenRequest);

    await msalInstance.acquireTokenRedirect(tokenRequest);
  } catch (error) {
    logger.userErr(
      "Weiterleitung zu Anmeldung fehlgeschlagen, bitte versuche die Seite neu zu laden.",
      error,
    );
  }
}

const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_B2C_CLIENT_ID as string,
    authority: `https://${
      import.meta.env.VITE_B2C_TENANT_NAME as string
    }.b2clogin.com/${
      import.meta.env.VITE_B2C_TENANT_NAME as string
    }.onmicrosoft.com/${
      import.meta.env.VITE_B2C_SIGN_IN_POLICY_NAME as string
    }`,
    knownAuthorities: [
      `${import.meta.env.VITE_B2C_TENANT_NAME as string}.b2clogin.com`,
    ],
    redirectUri: window.location.origin + "/callback",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

export const msalInstance: PublicClientApplication =
  new PublicClientApplication(msalConfig);

export async function loginRedirect(
  domainHint: string,
  state: string,
): Promise<void> {
  const loginRequest: RedirectRequest = {
    scopes,
    state,
    ...(domainHint && { domainHint }),
    redirectStartPage: "/callback",
  };
  logger.info("Logging in", loginRequest);

  try {
    // Check if there's an interaction in progress
    await msalInstance.loginRedirect(loginRequest);
  } catch (error) {
    logger.debugErr("Login error", error);
    throw error;
  }
}

export async function logout(): Promise<void> {
  logger.info("Logging out");
  const logoutRequest: EndSessionRequest = {
    postLogoutRedirectUri: "/signin",
  };
  await msalInstance.logoutRedirect(logoutRequest);
}

export function getAccount(): AccountInfo | null {
  return msalInstance.getAllAccounts()[0];
}

export async function handleAuthenticationResult(
  result: AuthenticationResult,
  router: Router,
) {
  const authStore = useAuthStore();
  authStore.setToken(result.idToken);
  // Set the active account
  if (!result.account?.idTokenClaims?.exp) {
    throw new Error("Token does not contain expiration time");
  }
  const account: AccountInfo = result.account;
  msalInstance.setActiveAccount(account);
  authStore.setUser(account as Account);
  try {
    // Redirect the user back to the original URL on token refresh
    const path = JSON.parse(result.state as string).path;
    if (path) {
      router.push(path);
    }
  } catch (error: any) {
    // No state: redirect to home
    logger.debugErr("Error parsing state. Redirecting to home.", error);
    router.push("/");
  }
}
