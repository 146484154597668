// src/store/completions.ts
import { IFrontendCompletion } from "@backend/interfaces/events/completions";
import { defineStore } from "pinia";
import { watch } from "vue";
import debounce from "just-debounce-it";
import { IFormElement, ITemplate } from "@backend/models/Template";

let stopWatcher = () => {};

export const useCompletionsStore = defineStore({
  id: "completions",
  state: () => ({
    template: {} as ITemplate,
    templateVariables: [] as (IFormElement & { value?: string })[],
    completions: [] as IFrontendCompletion[],
    activeMessageTabIndex: 0,
    selectedMessageIndex: 0,
    activeImageIndex: 0,
    isInitialized: false,
  }),
  getters: {
    activeCompletion: (state) => state.completions[state.activeMessageTabIndex],
  },
  actions: {
    initialize(template: ITemplate) {
      const storedCompletions = localStorage.getItem(
        `completions-${template.uuid}`,
      );
      this.completions = storedCompletions ? JSON.parse(storedCompletions) : [];
      this.template = template;
      this.activeMessageTabIndex = 0;
      this.selectedMessageIndex = 0;
      this.activeImageIndex = 0;

      // Stop the previous watcher if it exists
      stopWatcher();

      // Start watching the completions array after it's initialized
      stopWatcher = watch(
        () => this.completions,
        debounce((newCompletions: IFrontendCompletion[]) => {
          localStorage.setItem(
            `completions-${this.template.uuid}`,
            JSON.stringify(newCompletions.slice(-10)),
          );
        }, 500),
        { deep: true },
      );

      // Watch the activeMessageTabIndex and update activeImageIndex accordingly
      watch(
        () => this.activeMessageTabIndex,
        (newIndex) => {
          if (this.completions[newIndex]?.image_data) {
            this.activeImageIndex =
              this.completions[newIndex].image_data!.length - 1;
          } else {
            this.activeImageIndex = 0;
          }
        },
      );
      this.isInitialized = true;
    },
    reset() {
      stopWatcher();
      stopWatcher = () => {};
      this.completions = [];
      this.template = {} as ITemplate;
      this.activeMessageTabIndex = 0;
      this.selectedMessageIndex = 0;
      this.activeImageIndex = 0;
      this.isInitialized = false;
    },
    clearCompletions() {
      this.completions = [];
      this.activeMessageTabIndex = 0;
      this.selectedMessageIndex = 0;
      this.activeImageIndex = 0;
    },
    clearAllCompletions() {
      this.clearCompletions();
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.startsWith("completions-")) {
          localStorage.removeItem(key);
        }
      }
    },
    getAllCompletions() {
      const allCompletions: Record<string, any[]> = {};
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.startsWith("completions-")) {
          const uuid = key.split("-")[1];
          allCompletions[uuid] = JSON.parse(localStorage.getItem(key) || "[]");
        }
      }
      return allCompletions;
    },
    destroy() {
      this.completions = [];
      this.template = {} as ITemplate;
      this.activeMessageTabIndex = 0;
      this.selectedMessageIndex = 0;
      this.activeImageIndex = 0;
      stopWatcher();
      this.isInitialized = false;
    },
  },
});
