// src/store/auth.ts
import { defineStore } from "pinia";
import {
  loginRedirect,
  getAccount,
  acquireTokenRedirect,
  acquireTokenSilent,
  logout,
} from "@/auth";
import logger from "@/utils/logging";
import { useUserStore } from "@/store/user";
import { useCompletionsStore } from "@/store/completions";
import { useCacheStore } from "@/store/cache";
import { StorageSerializers, useLocalStorage } from "@vueuse/core";
import { AccountInfo } from "@azure/msal-browser";
import { Mutex } from "async-mutex";

export interface Account extends AccountInfo {
  idTokenClaims: {
    exp: number;
    idp: string;
    extension_isAdmin?: boolean;
    extension_EmailAddress?: string;
  };
  name: string;
  username: string;
}

const TEN_MINUTES = 10 * 60 * 1000;
const ONE_MINUTE = 60 * 1000;
const INIT_MUTEX = new Mutex();
INIT_MUTEX.acquire();

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    authMutex: new Mutex(),
    initializationMutex: INIT_MUTEX,
    tokenRefreshTimeoutTimer: null as ReturnType<typeof setTimeout> | null,
    tokenRefreshChecksTimer: null as ReturnType<typeof setInterval> | null,
    isInitialized: false,
    account: useLocalStorage<Account | null>("user", null, {
      serializer: StorageSerializers.object,
    }),
    token: useLocalStorage<string>("token", ""),
    expirationTime: useLocalStorage("expirationTime", new Date(Date.now())),
    lastSocketInteraction: Date.now(),
    socketReconnect: () => {
      logger.debugWarn("Socket reconnect method not set but used");
    },
  }),
  actions: {
    setInitializationFinished() {
      if (this.isInitialized) {
        logger.warn("Initialization finished twice");
        return;
      }
      this.isInitialized = true;
      this.startTokenRefreshTimeout();
      this.initializationMutex.release();
    },
    setUser(acc: Account) {
      this.account = acc;
      this.expirationTime = new Date(acc.idTokenClaims.exp * 1000);
      logger.debugInfo("User object updated");
    },
    async setToken(token: string) {
      this.token = token;
    },
    async getToken(silentOnly = false, forceRefresh = false): Promise<string> {
      await this.initializationMutex.waitForUnlock();
      if (this.isValid()) {
        return this.token;
      }

      logger.debugInfo(
        `Getting token (silent: ${silentOnly}, force: ${forceRefresh})...`,
        this.expirationTime.toISOString(),
      );
      await this.requestToken(silentOnly, forceRefresh);
      return this.token;
    },
    async requestToken(
      silentOnly: boolean,
      forceRefresh = false,
      reconnect = false,
    ): Promise<boolean> {
      // Make sure only one request is made at a time
      await this.authMutex.acquire();
      try {
        await acquireTokenSilent(forceRefresh);
        if (reconnect) this.socketReconnect();
        this.authMutex.release();
        return true;
      } catch (error) {
        // No token silently available, try to get one via redirect
        logger.debugWarn("Acquire token silent failed", error);
        if (silentOnly) {
          this.authMutex.release();
          return false;
        }
        logger.debugInfo("Acquiring token via redirect...");
        await acquireTokenRedirect(
          JSON.stringify({ path: window.location.pathname }),
          this.getDomainHint(this.account?.idTokenClaims.idp),
        );
        // This is only reached if acquireTokenRedirect throws an error
        this.authMutex.release();
        return false;
      }
    },
    getDomainHint(idp?: string): string | undefined {
      if (!idp) return undefined;
      if (idp.startsWith("https://login.microsoftonline.com")) {
        return "commonaad";
      }
      return idp;
    },
    async forceRefreshTokenSilently() {
      if (!this.account) return;
      if (Date.now() - this.lastSocketInteraction < ONE_MINUTE) {
        logger.info(
          `Not refreshing token, last socket interaction was ${Math.round(
            (Date.now() - this.lastSocketInteraction) / 1000,
          )} seconds ago`,
        );
        return;
      }
      logger.info("Refreshing expiring token...");
      await this.requestToken(true, true, true);
    },
    _startTokenRefreshChecks() {
      this.tokenRefreshChecksTimer = setInterval(() => {
        this.forceRefreshTokenSilently();
      }, ONE_MINUTE);
      logger.info("Token refresh checks started");
    },
    startTokenRefreshTimeout() {
      if (this.tokenRefreshTimeoutTimer !== null) {
        clearInterval(this.tokenRefreshTimeoutTimer);
      }
      if (this.tokenRefreshChecksTimer !== null) {
        clearTimeout(this.tokenRefreshChecksTimer);
      }
      const timeToRefresh =
        this.expirationTime.getTime() - Date.now() - TEN_MINUTES;
      logger.info(
        `Token refresh timeout set to ${new Date(
          Date.now() + timeToRefresh,
        ).toISOString()}}`,
      );
      this.tokenRefreshTimeoutTimer = setTimeout(() => {
        this._startTokenRefreshChecks();
      }, timeToRefresh);
    },
    isValid(): boolean {
      return (
        this.account !== null &&
        this.token !== "" &&
        Date.now() < this.expirationTime?.getTime()
      );
    },
    async logout() {
      this.account = null;
      this.token = "";
      this.expirationTime = new Date(Date.now());
      useUserStore().destroy();
      useCompletionsStore().destroy();
      useCacheStore().destroy();
      await logout();
    },
    async login(domainHint: string) {
      await loginRedirect(domainHint, JSON.stringify({ path: "/" }));
      const account = getAccount();
      if (!account) {
        throw new Error("Login failed, no account found");
      }
      this.setUser(account as Account);
    },
  },
});
