//src/router/index.ts
import { createRouter, createWebHistory } from "vue-router";
import StartPage from "@/views/StartPage.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "start-page",
      component: StartPage,
      alias: "/templates",
    },
    {
      path: "/callback",
      name: "auth-callback",
      component: () => import("@/views/AuthCallback.vue"),
    },
    {
      path: "/statistics",
      name: "statistics",
      component: () => import("@/views/TemplateStatistics.vue"),
      meta: {
        prefetch: false,
      },
    },
    {
      path: "/manage-templates",
      name: "manage-templates",
      component: () => import("@/views/AdminTemplateManagement.vue"),
      meta: {
        prefetch: false,
      },
    },
    {
      path: "/manage-models",
      name: "manage-models",
      component: () => import("@/views/AdminAIModelManagement.vue"),
      meta: {
        prefetch: false,
      },
    },
    {
      path: "/manage-categories",
      name: "manage-categories",
      component: () => import("@/views/AdminCategoryManagement.vue"),
      meta: {
        prefetch: false,
      },
    },
    {
      path: "/manage-users",
      name: "manage-users",
      component: () => import("@/views/AdminUserManagement.vue"),
      meta: {
        prefetch: false,
      },
    },
    {
      path: "/manage-roles",
      name: "manage-roles",
      component: () => import("@/views/AdminRoleManagement.vue"),
      meta: {
        prefetch: false,
      },
    },
    {
      path: "/categories/:id",
      name: "TemplatesByCategory",
      component: () => import("@/views/TemplatesByCategory.vue"),
      props: true,
    },
    {
      path: "/my-templates",
      name: "my-templates",
      component: () => import("@/views/MyTemplates.vue"),
    },
    {
      path: "/my-favorites",
      name: "my-favorites",
      component: () => import("@/views/MyFavoriteTemplates.vue"),
    },
    {
      path: "/signin",
      name: "signin",
      component: () => import("@/views/SignIn.vue"),
      meta: {
        prefetch: false,
      },
    },
    {
      path: "/templates/:id",
      name: "run-template",
      component: () => import("@/views/RunTemplate.vue"),
    },
    {
      path: "/create-template",
      name: "CreateTemplate",
      component: () => import("@/views/CreateTemplate.vue"),
    },
    {
      path: "/edit-template/:id",
      name: "EditTemplate",
      component: () => import("@/views/EditTemplate.vue"),
    },
    {
      path: "/duplicate-template/:id",
      name: "DuplicateTemplate",
      component: () => import("@/views/DuplicateTemplate.vue"),
    },
    {
      path: "/categories",
      name: "categories",
      component: () => import("@/views/CategoriesList.vue"),
    },
    {
      path: "/chatbot",
      name: "chatbot",
      // component: () => import("@/views/ChatbotTemplate.vue"),
      component: () => import("@/views/ChatbotTemplate.vue"),
    },
    {
      path: "/dall-e",
      name: "dall-e",
      // component: () => import("@/views/ChatbotTemplate.vue"),
      component: () => import("@/views/DallETemplate.vue"),
    },
  ],
});

/**
 * This function will prefetch specific routes.
 */
async function prefetchAsyncRoutes() {
  // iterate all routes and if the component is async - prefetch it!
  for (const route of router.getRoutes()) {
    if (!route.components || route.meta.prefetch === false) continue;

    // most routes have just a "default" component unless named views are used - iterate all entries just in case
    for (const componentOrImporter of Object.values(route.components)) {
      if (typeof componentOrImporter === "function") {
        try {
          // prefetch the component and wait until it finishes before moving to the next one
          await (componentOrImporter as Function)();
        } catch (err) {
          // ignore failing requests
        }
      }
    }
  }
}

window.addEventListener("load", prefetchAsyncRoutes);

export default router;
