import { marked } from "marked";
import DOMPurify from "dompurify";
import { CompletionMessage } from "@backend/socketEvents";

/**
 * Formats a completion message by sanitizing the HTML content and counting the number of tokens.
 * @param message - The message to format.
 * @returns The formatted message.
 */
export async function formatMessage(message: CompletionMessage) {
  // Use dynamic import to reduce bundle size (big dependency)
  const { isWithinTokenLimit } = await import("gpt-tokenizer");
  return {
    ...message,
    html: DOMPurify.sanitize(marked.parse(message.content)),
    tokens: isWithinTokenLimit(message.content, Infinity) as number,
  };
}
