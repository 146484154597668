// src/store/socket.ts
import { defineStore } from "pinia";
import { io, Socket } from "socket.io-client";
import { useAuthStore } from "@/store/auth";
import logger from "@/utils/logging";
import { checkForMajorVersionUpdate } from "@/utils";
import * as Sentry from "@sentry/vue";
import { version } from "../../../package.json";

interface EventListener {
  event: string;
  listener: (...args: any[]) => void;
}

export const useSocketStore = defineStore({
  id: "socket",
  state: () => ({
    isInitialized: false,
    connected: false,
    socket: null as Socket | null,
    eventListeners: [] as EventListener[],
    lastSocketInteraction: null as number | null,
  }),
  actions: {
    async initialize() {
      try {
        const backendUrl: string = import.meta.env.VITE_BACKEND_URL as string;
        const auth = useAuthStore();

        this.socket = io(backendUrl, {
          auth: {
            token: await auth.getToken(true),
          },
        });

        if (!this.socket) {
          throw new Error("Socket connection failed");
        }
        this.socket.on("connect", () => {
          logger.debugInfo("WebSocket connected");
          this.connected = true;
        });

        this.socket.on("version", (version: string) => {
          checkForMajorVersionUpdate(version);
        });

        this.socket.on("unauthorized", (error: { message: string }) => {
          const msg = "WebSocket unauthorized";
          logger.debugErr(msg, new Error(msg), error);
          this.connected = false;
          // If unauthorized, try to reconnect with a new token
          this.reconnect();
        });

        this.socket.on("connect_error", (error: { message: string }) => {
          const msg = "WebSocket connect error";
          logger.debugErr(msg, new Error(msg), error);
          this.connected = false;
        });

        this.socket.on("disconnect", () => {
          logger.debugWarn("WebSocket disconnected");
          this.connected = false;
        });

        this.socket.onAny(() => {
          auth.lastSocketInteraction = Date.now();
        });

        this.eventListeners.forEach(
          ({ event, listener }) => this.socket?.on(event, listener),
        );
        auth.socketReconnect = this.reconnect;
        this.isInitialized = true;
      } catch (error) {
        logger.userErr(
          "Verbindung fehlgeschlagen, bitte versuche die Seite neu zu laden.",
          error,
        );
      }
    },
    async reconnect() {
      if (this.socket) {
        this.eventListeners.forEach(({ event }) => this.socket?.off(event));
        this.socket.close();
      }
      await this.initialize();
    },
    on(event: string, listener: (...args: any[]) => void) {
      this.eventListeners.push({ event, listener });
      this.socket?.on(event, listener);
    },
    off(event: string, listener?: (...args: any[]) => void) {
      if (listener !== undefined) {
        return this.offSpecific(event, listener);
      }
      this.eventListeners = this.eventListeners.filter(
        (el) => el.event !== event,
      );
      this.socket?.off(event);
    },
    offSpecific(event: string, listener: (...args: any[]) => void) {
      this.eventListeners = this.eventListeners.filter(
        (el) => el.event !== event && el.listener !== listener,
      );
      this.socket?.off(event, listener);
    },
    once(event: string, listener: (...args: any[]) => void) {
      this.socket?.once(event, listener);
    },
    emit(event: string, data: Record<string, any>) {
      data.replayId =
        (Sentry.getCurrentHub()
          .getIntegration(Sentry.Replay)
          ?.getReplayId() as string) || "No replay ID found";
      data.frontendVersion = version;
      this.socket?.emit(event, data);
    },
  },
});
