import { marked } from "marked";
import { SynchronousOptions, markedHighlight } from "marked-highlight";
import prism, { Languages } from "prismjs";
import "prismjs/plugins/toolbar/prism-toolbar";
import "prismjs/plugins/toolbar/prism-toolbar.css";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.js";
import "prismjs/plugins/show-language/prism-show-language.js";
import "prismjs/plugins/custom-class/prism-custom-class";
import "prismjs/plugins/autoloader/prism-autoloader";

/**
 * Initialize code highlighting
 */
export function initializeCodeHighlighting(): void {
  prism.plugins.autoloader.languages_path =
    "https://unpkg.com/prismjs@latest/components/";
  prism.plugins.customClass.map({ number: "prism-number", tag: "prism-tag" });

  const markedHighlightOptions: SynchronousOptions = {
    langPrefix: "hljs language-",
    highlight: (code: string, lang: keyof Languages): string => {
      if (prism.languages[lang]) {
        return prism.highlight(code, prism.languages[lang], lang as string);
      } else {
        return code;
      }
    },
  };
  marked.use(markedHighlight(markedHighlightOptions));
}
