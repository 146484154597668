// frontend/src/main.ts
import {version} from "../../package.json";
import * as Sentry from "@sentry/vue";
import {ExtraErrorData} from "@sentry/integrations";
import {createApp} from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue-toast-notification/dist/theme-sugar.css";

import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faUserSecret, fas} from "@fortawesome/free-solid-svg-icons";

import {createPinia} from "pinia";

library.add(faUserSecret);
library.add(fas);

import App from "@/App.vue";
import router from "@/router";

import "@/assets/main.css";

const app = createApp(App);
try {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [`${import.meta.env.VITE_BACKEND_URL}/api`],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay({
                maskAllInputs: false,
                maskAllText: false,
                blockAllMedia: false,
                mask: ["[data-private]"],
                networkDetailAllowUrls: [import.meta.env.VITE_BACKEND_URL],
            }),
            new ExtraErrorData({
                depth: 7,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        release: `frontend@${version}`,
        environment: import.meta.env.MODE,
    });
} catch (e) {
  console.error('Error initialising vite, invalid dsn!');
}

const pinia = createPinia();
app.use(pinia);

app.component("FontAwesomeIcon", FontAwesomeIcon);
app.use(router);

app.mount("#app");
